export default {
    methods: {
        canAccess: function (action) {
            let user = localStorage.getItem('user');
            user = JSON.parse(user);
            return user.my_abilities.some(x => x === action) || user.my_abilities.some(x => x === '*')
        },
        hasAbilities: function () {
            let user = localStorage.getItem('user');
            user = JSON.parse(user);
            return user.my_abilities.length>0
        }

    }
}