<template>
  <div class="p-grid">
    <Card class="center" v-if="!requirePassword">
      <template #header>
        <img alt="logo" src="../../../assets/logo1.jpg" height="350">
      </template>
      <template #title>
        <p style="text-align: center">Bienvenido</p>
      </template>
      <template #content>
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-user"></i>
                    </span>
              <InputText placeholder="Username" v-model="credentials.email"/>
            </div>
          </div>

          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-lock"></i>
                        </span>
              <InputText placeholder="Password" type="password" v-model="credentials.password" @keyup.enter="login" />
            </div>
          </div>
        </div>
      </template>
      <template #footer class="p-align-end">
        <Toolbar class="row">
          <template #right style="width: 100%">
            <Button :disabled="isLoading || credentials.password === '' || credentials.email===''"
                    icon="pi pi-check" label="Login" @click="login" class="p-align-end"/>

          </template>
        </Toolbar>
        <ProgressBar mode="indeterminate" v-if="isLoading"/>

      </template>
    </Card>
    <Card class="center" v-else>
      <template #header>
        <img alt="logo" src="../../../assets/logo1.jpg" height="350">
      </template>
      <template #title>
        <p style="text-align: center">Cambio de clave</p>
      </template>
      <template #content>
        <p style="text-align: center">Estimado usuario, la clave ingresada es temporal, se requiere que la
          actualice</p>
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-lock"></i>
                                </span>
              <InputText type="password" placeholder="Password" v-model="password.password"/>
            </div>
          </div>
          <div class="p-col-12 p-md-12">
            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-lock"></i>
                                 </span>
              <InputText placeholder="Confirmación de password" type="password"
                         v-model="password.password_confirmation"/>
            </div>
          </div>
        </div>
      </template>
      <template #footer class="p-align-end">
        <Toolbar class="row">
          <template #left style="width: 100%">
            <Button icon="pi pi-times" label="Atras" @click="cancelReset"
                    class="p-align-end p-button-secondary"/>
          </template>
          <template #right style="width: 100%">
            <Button :disabled="isLoading || password.password === '' || password.password_confirmation ===''"
                    icon="pi pi-check" label="Cambiar" @click="resetPassword" class="p-align-end"/>

          </template>
        </Toolbar>
        <ProgressBar mode="indeterminate" v-if="isLoading"/>
      </template>
    </Card>
  </div>
</template>
<script>
import service from "../service/login.service";

const REQ_PASS_KEY = 'y';
export default {
  data() {
    return {
      isLoading: false,
      error: false,
      requirePassword: false,
      errorMessage: null,
      credentials: {
        email: '',
        password: '',
        rememberMe: false,
      },
      password: {
        password: '',
        password_confirmation: ''
      }
    };
  },
  name: 'auth-login',
  metaInfo: {
    title: 'Login'
  },
  created() {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('jwt');
    sessionStorage.removeItem('menu');
  },
  mounted() {
    const elements = document.getElementsByClassName('p-sidebar-mask');
    elements.forEach(element => {
      element.parentNode.removeChild(element);
    });
  },
  methods: {
    resetPassword() {
      this.error = false;
      this.isLoading = true;
      service.resetPassword(this.password).then((x) => {
        this.$toast.add({
          severity: 'success',
          summary: x.data.message,
          detail: 'Su contraseña ha sido cambiada correctamente',
          life: this.$utils.toastLifeTime()
        });
        this.cancelReset();
        this.credentials.password = '';
      }).catch((x) => {
        const message = x.response.data;
        let error = '';
        Object.keys(message).forEach(key => {
          error += error + message[key] && message[key][0] ? message[key][0] + '\n' : '';
        });
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => {
        this.isLoading = false;
      })
    },
    cancelReset() {
      this.requirePassword = false;
      localStorage.clear();
    },
    login() {
      this.error = false;
      this.isLoading = true;
      service.login(this.credentials).then(x => {
        const user = x.data.user;
        user.employee = x.data.employee;
        localStorage.setItem('jwt', x.data.access_token);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('menu', JSON.stringify(x.data.menu));
        if (x.data.user && x.data.user.change_password === REQ_PASS_KEY) {
          this.requirePassword = true;
        } else {
          this.$router.push({name: 'dashboard'});
        }

      }).catch(() => {
        this.error = true;
        this.errorMessage = 'Credentials are wrong';
        this.credentials.password = '';
        this.$toast.add({
          severity: 'error',
          summary: 'Error de inicio',
          detail: 'Credenciales incorrectas',
          life: this.$utils.toastLifeTime()
        });

      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false
        }, 500)
      })
    }
  }

};
</script>
<style scoped lang="scss">
.center {
  width: 450px !important;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

}

.p-toolbar {
  border: none;
  background-color: white;
}

.p-card-body {
  padding-bottom: 0;
}

.p-progressbar {
  height: 1px;
  margin-bottom: -14px;

}
</style>
