import AppFooter from "@/modules/layout/layout/AppFooter";
import AppMenu from "@/modules/layout/layout/AppMenu";
import AppProfile from "@/modules/layout/layout/AppProfile/";
import AppTopBar from "@/modules/layout/layout/AppTopbar";
import bouncer from "@/helpers/bouncer";
import menu from "@/helpers/menu";

export default {
  mixins: [bouncer, menu],
  data() {
    return {
      layoutMode: "static",
      layoutModeStatic: "static",
      layoutModeOverlay: "overlay",
      layoutColorMode: "dark",
      innerWidthWindow: 1024,
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;
      if (this.isDesktop()) {
        if (this.layoutMode === this.layoutModeOverlay) {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }
          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === this.layoutModeStatic) {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }
      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) {
        element.classList.remove(className);
      } else {
        const pattern = "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)";
        const flags = "gi";
        element.className = element.className.replace(
          new RegExp(pattern, flags),
          " "
        );
      }
    },
    isDesktop() {
      return window.innerWidth > this.innerWidthWindow;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === this.layoutModeStatic)
          return !this.staticMenuInactive;
        else if (this.layoutMode === this.layoutModeOverlay)
          return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
  },
  computed: {
    containerClass() {
      const layoutWrapper = "layout-wrapper";
      const inputStyle = "filled";
      const containerClassOptions = {
        "layout-overlay": this.layoutMode === this.layoutModeOverlay,
        "layout-static": this.layoutMode === this.layoutModeStatic,
        "layout-static-sidebar-inactive":
          this.staticMenuInactive && this.layoutMode === this.layoutModeStatic,
        "layout-overlay-sidebar-active":
          this.overlayMenuActive && this.layoutMode === this.layoutModeOverlay,
        "layout-mobile-sidebar-active": this.mobileMenuActive,
        "p-input-filled": this.$appState.inputStyle === inputStyle,
        "p-ripple-disabled": this.$primevue.ripple === false,
      };
      return [layoutWrapper, containerClassOptions];
    },
    sidebarClass() {
      const layoutColorModeDark = "dark";
      const layoutColorModeLight = "light";
      const layoutSidebar = "layout-sidebar";
      const sidebarClassOptions = {
        "layout-sidebar-dark": this.layoutColorMode === layoutColorModeDark,
        "layout-sidebar-light": this.layoutColorMode === layoutColorModeLight,
      };
      return [layoutSidebar, sidebarClassOptions];
    },
    logo() {
      return "assets/layout/images/logo.png";
    },
  },
  beforeUpdate() {
    const mobileMenuActiveClass = "body-overflow-hidden";
    if (this.mobileMenuActive)
      this.addClass(document.body, mobileMenuActiveClass);
    else this.removeClass(document.body, mobileMenuActiveClass);
  },
  async mounted() {
    const dispatchType = "auth/setSession";
    const menuKey = "menu";
    await this.$store.dispatch(dispatchType);
    this.menu = await JSON.parse(localStorage.getItem(menuKey));
  },
  components: {
    AppFooter: AppFooter,
    AppMenu: AppMenu,
    AppProfile: AppProfile,
    AppTopBar: AppTopBar,
  },
};
