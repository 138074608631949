import httpService from '../../../helpers/http.service'

export default {
  login (payload) {
    return httpService.post('auth/login', payload)
  },
  resetPassword (payload) {
    return httpService.post('auth/password', payload)
  },
  token (payload) {
    return httpService.post('auth/token', payload)
  }
}
