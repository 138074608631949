<template>
	<div class="layout-topbar">
		<button class="p-link layout-menu-button" @click="onMenuToggle">
			<span class="pi pi-bars"></span>
		</button>
		<div class="layout-topbar-icons">

			<button class="p-link" @click="logout">
				<span class="layout-topbar-item-text">Salir</span>
				<span class="layout-topbar-icon pi pi-fw pi-power-off"></span>
			</button>
		</div>
	</div>
</template>

<script>
export default {
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
      logout() {
        localStorage.clear();
        this.$router.push({name: 'login'})
      }
    }
}
</script>