<template>
  <div class="layout-profile">
    <div>
      <img
        class="profile-photo"
        :alt="user.name"
        :src="
          'https://ui-avatars.com/api/?name=' +
          user.name +
          '&color=ffffff&background=0ab5ba'
        "
      />
    </div>
    <button class="p-link layout-profile-link" @click="onClick">
      <span class="username">{{ user.name }}</span>
      <i class="pi pi-fw pi-cog"></i>
    </button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <li @click="logout()">
          <button class="p-link">
            <i class="pi pi-fw pi-power-off"></i><span>Logout</span>
          </button>
        </li>
      </ul>
    </transition>
  </div>
</template>
<script src="./AppProfile.js"></script>
<style src="./AppProfile.scss" lang="scss" scoped></style>
