const usr = localStorage.getItem('user');
const user = JSON.parse(usr);

function menuEmployees() {
    let object = null
    if (user.my_abilities.find(x => x === '*') !== undefined ||
        user.my_abilities.find(x => x === 'empleados_create') !== undefined ||
        user.my_abilities.find(x => x === 'empleados_read') !== undefined ||
        user.my_abilities.find(x => x === 'empleados_update') !== undefined ||
        user.my_abilities.find(x => x === 'empleados_delete') !== undefined) {
        object = {
            label: 'Empleados', icon: 'pi pi-fw pi-user', items: []
        };
        if (user.my_abilities.find(x => x === 'empleados_read') !== undefined ||
            user.my_abilities.find(x => x === '*') !== undefined) {
            object.items.push({label: 'Lista de empleados', icon: 'pi pi-fw pi-bars', to: '/employee'});
        }
        if (user.my_abilities.find(x => x === 'empleados_create') !== undefined ||
            user.my_abilities.find(x => x === '*') !== undefined) {
            object.items.push({label: 'Registro de empleados', icon: 'pi pi-fw pi-th-large', to: '/employeecrud'});
        }
    }
    return object
}

function menuAdmin() {
    let administrar = {
        label: 'Administrar', icon: 'pi pi-fw pi-globe', items: []
    };
    let object = false
    if (user.my_abilities.find(x => x === '*') !== undefined ||
        user.my_abilities.find(x => x === 'cargos_read') !== undefined) {
        administrar.items.push({label: 'Cargos', icon: 'pi pi-fw pi-th-large', to: '/charge'});
        object=true;
    }

    if (user.my_abilities.find(x => x === '*') !== undefined ||
        user.my_abilities.find(x => x === 'organizaciones_read') !== undefined) {
        administrar.items.push({label: 'Organizaciones', icon: 'pi pi-fw pi-chart-bar', to: '/organizations'});
        object=true;
    }
    if (user.my_abilities.find(x => x === '*') !== undefined ||
        user.my_abilities.find(x => x === 'origen_matriculas_read') !== undefined) {
        administrar.items.push({label: 'Origen de matrículas', icon: 'pi pi-fw pi-spinner', to: '/origin'});
        object=true;
    }

    if (user.my_abilities.find(x => x === '*') !== undefined ||
        user.my_abilities.find(x => x === 'paises_read') !== undefined) {
        administrar.items.push({label: 'Paises', icon: 'pi pi-fw pi-list', to: '/country'});
        object=true;
    }
    if (user.my_abilities.find(x => x === '*') !== undefined ||
        user.my_abilities.find(x => x === 'ciudades_read') !== undefined) {
        administrar.items.push({label: 'Ciudades', icon: 'pi pi-fw pi-table', to: '/city'});
        object=true;
    }
    if (user.my_abilities.find(x => x === '*') !== undefined ||
        user.my_abilities.find(x => x === 'empresas_read') !== undefined) {
        administrar.items.push({label: 'Empresas', icon: 'pi pi-fw pi-sitemap', to: '/company'});
        object=true;
    }
    if (user.my_abilities.find(x => x === '*') !== undefined ||
        user.my_abilities.find(x => x === 'conceptos_liquidaciones_read') !== undefined) {
        administrar.items.push({label: 'Concepto liquidaciones', icon: 'pi pi-fw pi-clone', to: '/cliquidations'});
        object=true;
    }
    if (user.my_abilities.find(x => x === '*') !== undefined ||
        user.my_abilities.find(x => x === 'formas_pagos_read') !== undefined) {
        administrar.items.push({label: 'Formas de pago', icon: 'pi pi-fw pi-plus', to: '/payment'});
        object=true;
    }
    if (user.my_abilities.find(x => x === '*') !== undefined ||
        user.my_abilities.find(x => x === 'filiales_read') !== undefined) {
        administrar.items.push({label: 'Filiales', icon: 'pi pi-fw pi-file', to: '/filial'});
        object=true;
    }
    if (user.my_abilities.find(x => x === '*') !== undefined ||
        user.my_abilities.find(x => x === 'planes_read') !== undefined) {
        administrar.items.push({label: 'Planes', icon: 'pi pi-fw pi-copy', to: '/plans'});
        object=true;
    }
    if (user.my_abilities.find(x => x === '*') !== undefined ||
        user.my_abilities.find(x => x === 'meses_read') !== undefined) {
        administrar.items.push({label: 'Meses', icon: 'pi pi-fw pi-calendar', to: '/month'});
        object=true;
    }
    if (user.my_abilities.find(x => x === '*') !== undefined ||
        user.my_abilities.find(x => x === 'semanas_read') !== undefined) {
        administrar.items.push({label: 'Semanas', icon: 'pi pi-fw pi-calendar', to: '/week'});
        object=true;
    }
    if (user.my_abilities.find(x => x === '*') !== undefined ||
        user.my_abilities.find(x => x === 'logs_read') !== undefined) {
        administrar.items.push({label: 'Logs del sistema', icon: 'pi pi-fw pi-list', to: '/log'});
        object=true;
    }

    if(object){
        return administrar
    }
    return null
}

function menuVerificacion() {
    let menu = {
        label: 'Verificación', icon: 'pi pi-fw pi-cog', items: []
    };
    let object = null
    if (user.my_abilities.find(x => x === 'titulares_read') !== undefined ||
        user.my_abilities.find(x => x === '*') !== undefined) {
        menu.items.push({label: 'Titulares', icon: 'pi pi-fw pi-th-large', to: '/verification/holders'});
        object=true
    }
    if(object){
        return menu;
    }
    return null
}

function menuRegistro() {
    let menu = {
        label: 'Registro', icon: 'pi pi-fw pi-table', items: []
    };
    let object = null
    if (user.my_abilities.find(x => x === 'lista_matriculas_comision_read') !== undefined ||
        user.my_abilities.find(x => x === '*') !== undefined) {
        menu.items.push({label: 'Lista de Matrículas con Comisión', icon: 'pi pi-fw pi-bars', to: '/commission/processed'});
        object=true
    }
    if (user.my_abilities.find(x => x === 'lista_liquidaciones_read') !== undefined ||
        user.my_abilities.find(x => x === '*') !== undefined) {
        menu.items.push( {label: 'Lista de Liquidaciones', icon: 'pi pi-fw pi-bars', to: '/liquidation/list'});
        object=true
    }
    if(object){
        return menu;
    }
    return null

}

function menuVerificaPagos() {
    let menu = {
        label: 'Gestión operativa', icon: 'pi pi-fw pi-bookmark', items: []
    };
    let object = null
    if (user.my_abilities.find(x => x === 'verifica_pagos_read') !== undefined ||
        user.my_abilities.find(x => x === '*') !== undefined) {
        menu.items.push({label: 'Verificar pagos', icon: 'pi pi-fw pi-check', to: '/verification/check'});
        object=true
    }
    if(object){
        return menu;
    }
    return null
}

function menuCobranza() {
    let menu = {
        label: 'Cobranzas', icon: 'pi pi-fw pi-bookmark', items: []
    };
    let object = null
    if (user.my_abilities.find(x => x === 'cobranzas_admin') !== undefined ||
        user.my_abilities.find(x => x === '*') !== undefined) {
        menu.items.push({label: 'Gestión', icon: 'pi pi-fw pi-check', to: '/raise-money/list'});
        object=true
    }
    if(object){
        return menu;
    }
    return null
}

function menuEstadisticas(){
    let menu = {
        label: 'Estadísticas', icon: 'pi pi-chart-bar', items: []
    };
    let object = null
    if (user.my_abilities.find(x => x === 'informe_modulo_maestro_read') !== undefined ||
        user.my_abilities.find(x => x === '*') !== undefined) {
        menu.items.push({label: 'Informe módulo maestro', icon: 'pi pi-fw pi-file', to: '/report/core'});
        object=true
    }
    if (user.my_abilities.find(x => x === 'informe_produccion_read') !== undefined ||
        user.my_abilities.find(x => x === '*') !== undefined) {
        menu.items.push({label: 'Informe de producción', icon: 'pi pi-fw pi-file', to: '/report/enroll'});
        object=true
    }
    if (user.my_abilities.find(x => x === 'informe_matriculas_read') !== undefined ||
        user.my_abilities.find(x => x === '*') !== undefined) {
        menu.items.push({label: 'Informe de matrículas', icon: 'pi pi-fw pi-file', to: '/report/enroll/monthly'});
        object=true
    }
    if(object){
        return menu;
    }
    return null

}

export default {
    methods: {
        menuOptions: function () {
            let options = [];
            options.push({label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/admin'})

            let employees = menuEmployees();
            if (employees !== null) {
                options.push(employees);
            }
            let admin = menuAdmin();
            if (admin !== null) {
                options.push(admin);
            }
            let verificacion = menuVerificacion();
            if (verificacion !== null) {
                options.push(verificacion);
            }

            let registro = menuRegistro();
            if (registro !== null) {
                options.push(registro);
            }
            let cobranza = menuCobranza();
            if (cobranza !== null) {
                options.push(cobranza);
            }

            let verificaPagos = menuVerificaPagos();
            if (verificaPagos !== null) {
                options.push(verificaPagos);
            }

            let estadisticas = menuEstadisticas();
            if (estadisticas !== null) {
                options.push(estadisticas);
            }

            return options
        }
    }
}