import {createStore} from 'vuex'
import auth from './modules/auth'
import enroll from './modules/enroll'
import bread from './modules/bread'

export default createStore({
    state: {

    },
    mutations: {

    },
    modules:{
        auth,
        enroll,
        bread
    }
});

